import ProducersPage from "@bottlebooks/bottlebooks-site-base/src/components/ProducersPage/ProducersPage.next";
import { LoaderFunctionArgs, useLoaderData, useParams } from "react-router-dom";
import { z } from "zod";
import { graphql } from "~/gql";
import { getFragment } from "~/gql-local/fragment-masking";
import graphQLClient from "~/graphQLClient";
import getCustomDomain from "../_getCustomDomain";
import getCollectionId from "../_getCollectionId";

const paramsSchema = z.object({
  locale: z.enum(["en", "de", "es", "fr"]),
});

export default function ProducersTemplate() {
  const { locale } = paramsSchema.parse(useParams());
  const data = useLoaderData();
  const event = getFragment(eventFragment, data);
  const producersMap = event?.producers.nodes.reduce((acc, producer) => {
    acc[producer.producerId] = producer;
    return acc;
  }, {});

  const extractedRegistrations = event?.registeredBrands.nodes
    .flatMap((registeredBrand) => {
      const producer = getFragment(fragment, registeredBrand);
      const classicProducer = producersMap[producer.brandId];
      return {
        ...classicProducer,
        ...producer,
        ...producer.profile,
        exhibitor: registeredBrand.registration.profile,
      };
    })
    .filter(Boolean);

  if (!extractedRegistrations) return <div>No registrations found.</div>;
  return (
    <ProducersPage
      collectionId={data?.collectionId}
      producers={extractedRegistrations}
      locale={locale}
      // TODO: Not sure what this is
      location={"/"}
    />
  );
}

export async function Loader(args: LoaderFunctionArgs) {
  const { locale } = paramsSchema.parse(args.params);
  const customDomain = getCustomDomain(args);
  const { event } = await graphQLClient.request(
    graphql(/* GraphQL */ `
      query ProducersPageByCustomDomain(
        $customDomain: String!
        $locale: ContentLocale
      ) {
        event: _unstable_collectionByCustomDomain(
          experimentalKey: "jonathan@bottlebooks.me: @bottlebooks/bottlebooks-site/src/pages/[locale]/_layout.tsx"
          customDomain: $customDomain
          locale: $locale
        ) {
          collectionId
          ... on Event {
            eventId
            ...ProducersPage_Event
          }
        }
      }
    `),
    {
      customDomain,
      locale,
      // Required to access to cached data.
      collectionId: await getCollectionId({ customDomain }),
    },
    { "bottlebooks-use-request-cache": "true" }
  );
  return event;
}

const eventFragment = graphql(/* GraphQL */ `
  fragment ProducersPage_Event on Event {
    eventId
    producers {
      nodes {
        producerId
        ...ProducersPage
      }
    }
    registeredBrands {
      nodes {
        __typename
        registrationId
        brandId
        # This is pretty ugly because ProducersPage has a fragment on Producer, not RegisteredBrand
        # This is required because producers resolver doesn't know about child collections
        producerId: brandId
        ...ProducerDetail_ProducersPage
        registration {
          ...ProducerListRow_Registration
        }
      }
    }
    # used to get the denomination summary for each producer
    registeredProducts {
      totalCount
      byProducer: group(field: producerId, sort: { fields: fieldValue }) {
        fieldValue
        totalCount
        nodes {
          product {
            ... on Wine {
              denomination
            }
          }
        }
      }
    }
  }
`);

const fragment = graphql(/* GraphQL */ `
  fragment ProducerDetail_ProducersPage on RegisteredBrand {
    ...ProducerCard_RegisteredBrand
    ...ProducerFilters_RegisteredBrand
    ...producerFilters
  }
`);
