import CollectionLayoutProvider from "@bottlebooks/bottlebooks-site-base/src/components/CollectionLayoutProvider.next";
import BaseLayout from "@bottlebooks/bottlebooks-site-base/src/components/Layout/BaseLayout";
import Layout from "@bottlebooks/bottlebooks-site-base/src/components/Layout/Layout.next";
import SEO from "@bottlebooks/bottlebooks-site-base/src/components/seo.next";
import EventDataProvider from "@bottlebooks/bottlebooks-site-base/src/components/SiteSearch/EventDataProvider.next";
import { LinkProvider } from "@bottlebooks/bottlebooks-site-base/src/useLink";
import { load } from "@bottlebooks/bottlebooks-site-base/src/wrapElement/lingui/i18n.config";
import mapToMenuItems from "@bottlebooks/bottlebooks-site-cms/src/@bottlebooks/bottlebooks-site-base/components/Layout/mapToMenuItems";
import { siteQuery } from "@bottlebooks/bottlebooks-site-cms/src/pages/cms-preview/pages/siteQuery";
import { i18n } from "@lingui/core";
import {
  fromNavigator,
  fromStorage,
  fromUrl,
  multipleDetect,
} from "@lingui/detect-locale";
import { I18nProvider } from "@lingui/react";
import { queryOptions, useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import {
  LoaderFunctionArgs,
  Outlet,
  redirect,
  useLoaderData,
  useLocation,
  useParams,
} from "react-router-dom";
import { z } from "zod";
import FirebaseProviders from "~/components/FirebaseProviders";
import { GoogleAnalyticsProvider } from "~/components/GoogleAnalyticsProvider";
import fetchSanity from "~/fetchSanity";
import { graphql, useFragment } from "~/gql";
import { ContentLocale } from "~/gql/graphql";
import graphQLClient from "~/graphQLClient";
import queryClient from "~/queryClient";
import getCollectionId from "./_getCollectionId";
import getCustomDomain from "./_getCustomDomain";

const paramsSchema = z.object({
  locale: z.enum(["en", "de", "es", "fr"]),
});

const fragment = graphql(/* GraphQL */ `
  fragment CollectionLayout on Collection {
    collectionId
    ...CollectionLayoutProvider
    name
    ...Layout
    ...SEO
  }
`);

function getQuery({
  customDomain,
  locale,
}: {
  customDomain: string;
  locale: ContentLocale;
}) {
  return queryOptions({
    queryKey: ["collection", locale, customDomain],
    queryFn: async () => {
      const result = await graphQLClient.request(
        graphql(/* GraphQL */ `
          query RootLayout($customDomain: String!, $locale: ContentLocale!) {
            collection: _unstable_collectionByCustomDomain(
              experimentalKey: "jonathan@bottlebooks.me: @bottlebooks/bottlebooks-site/src/pages/[locale]/_layout.tsx"
              customDomain: $customDomain
              locale: $locale
            ) {
              collectionId
              ...CollectionLayout
              site {
                templateSettings {
                  name
                  value
                }
                primaryColor
                accentColor
              }
            }
          }
        `),
        {
          customDomain,
          locale,
          // Required to access to cached data.
          collectionId: await getCollectionId({ customDomain }),
        },
        { "bottlebooks-use-request-cache": "true" }
      );
      if (!result.collection) throw new Error("No event");
      const collection = result.collection;
      const templateSettings = collection.site?.templateSettings ?? [];
      const projectId = templateSettings.find(
        ({ name }) => name === "projectId"
      )?.value;
      const bottlebooksConfiguration = {
        ...collection,
        isSanity: false,
        // TODO: implement resolvers to expose this data on GraphQL
        // primaryColor: site.primaryColor,
        // accentColor: site.accentColor,
      };
      if (!projectId) return bottlebooksConfiguration;
      const sanityResult = await fetchSanity(
        siteQuery,
        { collectionId: collection.collectionId },
        {
          projectId,
          "bottlebooks-use-request-cache": "true",
        }
      );
      const site = sanityResult.data?.allSite?.[0];
      if (!site) {
        console.log(
          "No CMS site configured for this collection. Using configuration from Bottlebooks."
        );
        return bottlebooksConfiguration;
      }
      const menuItems = mapToMenuItems({
        site,
        basePathname: `/${locale}`,
      });
      return {
        ...collection,
        logo: site.logo,
        site: {
          ...collection.site,
          menuItems,
          primaryColor: site.primaryColor ?? collection.site?.primaryColor,
          accentColor: site.accentColor ?? collection.site?.accentColor,
        },
        isSanity: true,
        projectId,
      };
    },
    throwOnError: true,
  });
}

function getSiteSearchQuery({
  customDomain,
  locale,
}: {
  customDomain: string;
  locale: ContentLocale;
}) {
  return queryOptions({
    queryKey: ["collection", locale, customDomain, "siteSearch"],
    queryFn: async () => {
      const result = await graphQLClient.request(
        graphql(/* GraphQL */ `
          query CollectionSearchByCustomDomain(
            $customDomain: String!
            $locale: ContentLocale!
          ) {
            collection: _unstable_collectionByCustomDomain(
              experimentalKey: "jonathan@bottlebooks.me: @bottlebooks/bottlebooks-site/src/pages/[locale]/_layout.tsx"
              customDomain: $customDomain
              locale: $locale
            ) {
              collectionId
              ...useEventData
            }
          }
        `),
        {
          customDomain,
          locale,
          // Required to access to cached data.
          collectionId: await getCollectionId({ customDomain }),
        },
        { "bottlebooks-use-request-cache": "true" }
      );
      if (!result.collection) throw new Error("No event");
      return result.collection;
    },
    throwOnError: true,
  });
}

type LoaderData = Awaited<ReturnType<typeof Loader>>;
export async function Loader(args: LoaderFunctionArgs) {
  const customDomain = getCustomDomain(args);
  const result = paramsSchema.safeParse(args.params);
  if (!result.success) {
    // We need to detect the locale
    // Let's assume that it's just missing the locale and try
    // to insert it.
    // This uses similar logic to the pages/index.tsx resolver
    const resolveCollectionId = await queryClient.fetchQuery(
      getQuery({ customDomain, locale: "en" })
    );
    const result = await graphQLClient.request(
      graphql(/* GraphQL */ `
        query CollectionLocale($collectionId: ID!) {
          collection(collectionId: $collectionId) {
            collectionId
            locales(format: RAW)
          }
        }
      `),
      { collectionId: resolveCollectionId.collectionId }
    );
    const locales = result.collection?.locales ?? [];

    const detectedLocales = multipleDetect(
      fromUrl("locale"),
      fromStorage("locale"),
      fromNavigator(),
      locales[0]
    );
    const locale =
      detectedLocales.find((locale) => locales.includes(locale)) ?? locales[0];
    return redirect(`/${locale}${window.location.pathname}`);
  }
  await Promise.all([
    queryClient.prefetchQuery(
      getQuery({ customDomain, locale: result.data.locale })
    ),
    queryClient.prefetchQuery(
      getSiteSearchQuery({ customDomain, locale: result.data.locale })
    ),
  ]);
  return {
    customDomain,
  };
}

export default function CollectionLayout() {
  const loaderData = useLoaderData() as LoaderData;
  const resolvedLoaderData = "customDomain" in loaderData ? loaderData : null;
  if (!resolvedLoaderData) throw new Error("No customDomain found");
  const customDomain = resolvedLoaderData?.customDomain;
  const { locale } = paramsSchema.parse(useParams());

  // Activate the locale
  load(locale);
  i18n.activate(locale);
  // @ts-expect-error - not sure why we are setting this property
  i18n._locale = locale;

  // Setup paths
  const paths = {
    products: "/products",
    exhibitors: "/exhibitors",
    producers: "/producers",
    base: `/${locale}`,
  };

  const { data } = useQuery(getQuery({ customDomain, locale }));
  const collection = useFragment(fragment, data);
  const { data: siteSearchData } = useQuery(
    getSiteSearchQuery({ customDomain, locale })
  );
  console.log("data.collectionId", data?.collectionId);
  if (!collection) return <div>Loading...</div>;

  return (
    <I18nProvider i18n={i18n}>
      <ScrollToTop />
      <CollectionLayoutProvider
        data={collection}
        collectionId={collection.collectionId}
      >
        <FirebaseProviders>
          <LinkProvider
            value={(context: any) =>
              context || paths ? { ...context, ...paths, locale } : undefined
            }
          >
            <EventDataProvider data={siteSearchData}>
              <GoogleAnalyticsProvider>
                <SEO data={collection} />
                <BaseLayout>
                  <Layout
                    data={collection}
                    locale={locale}
                    sx={{ backgroundColor: "transparent" }}
                  >
                    <Outlet />
                  </Layout>
                </BaseLayout>
              </GoogleAnalyticsProvider>
            </EventDataProvider>
          </LinkProvider>
        </FirebaseProviders>
      </CollectionLayoutProvider>
    </I18nProvider>
  );
}
function ScrollToTop() {
  // Extracts pathname property(key) from an object
  const { pathname } = useLocation();

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}
