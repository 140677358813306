const possibleTypes = {
  AbstractBrand: ['Producer', 'Exhibitor'],
  Collection: ['Event', 'ProductList', 'ShareList'],
  ExtendedProduct: ['Wine', 'Beer', 'Spirit', 'Cider'],
  FieldHistoryEntry: [
    'FieldChangeHistoryEntry',
    'FieldSetHistoryEntry',
    'FieldUnsetHistoryEntry',
    'FieldUnknownHistoryEntry',
  ],
  FieldValue: [
    'ChangeoverFieldValue',
    'DateFieldValue',
    'FloatFieldValue',
    'ImageFieldValue',
    'IntFieldValue',
    'MultiSelectFieldValue',
    'SelectFieldValue',
    'TextFieldValue',
    'YesNoFieldValue',
  ],
  File: ['UploadedFile', 'LiveFile', 'LinkedFile', 'LiveInfoSheet'],
  HistoryEntry: [
    'FieldChangeHistoryEntry',
    'FieldSetHistoryEntry',
    'FieldUnsetHistoryEntry',
    'FieldUnknownHistoryEntry',
    'ProductAddToCollectionHistoryEntry',
    'ProductRemoveFromCollectionHistoryEntry',
    'ProductChangeHistoryEntry',
  ],
  Product: ['ProductV1', 'Wine', 'Beer', 'Spirit', 'Cider', 'ExtendedProduct'],
  ProductHistoryEntry: [
    'ProductAddToCollectionHistoryEntry',
    'ProductRemoveFromCollectionHistoryEntry',
    'ProductChangeHistoryEntry',
  ],
  Registration: ['SingleRegistration', 'GroupedRegistration'],
};

export default possibleTypes;
