const typePolicies = {
  Beer: {
    keyFields: ['productId'],
    fields: {
      // Make sure that all image sizes are available on the cache.
      bottleImage: { merge: true },
      files: { merge: true },
    },
  },
  Brand: {
    keyFields: ['brandId'],
    fields: {
      // Make sure that all image sizes are available on the cache.
      logo: { merge: true },
    },
  },
  Event: { keyFields: ['eventId'] },
  Exhibitor: { keyFields: ['exhibitorId'] },
  ExportConfiguration: { keyFields: ['configurationId'] },
  Producer: { keyFields: ['producerId'] },
  Product: {
    keyFields: ['productId'],
    fields: {
      // Make sure that all image sizes are available on the cache.
      bottleImage: { merge: true },
      files: { merge: true },
    },
  },
  ProductList: {
    keyFields: ['productListId'],
    fields: {
      products: { merge: true },
    },
  },
  Query: {
    fields: {
      currentUser: { merge: true },
    },
  },
  ShareList: { keyFields: ['shareListId'] },
  Spirit: {
    keyFields: ['productId'],
    fields: {
      // Make sure that all image sizes are available on the cache.
      bottleImage: { merge: true },
      files: { merge: true },
    },
  },
  Wine: {
    keyFields: ['productId'],
    fields: {
      // Make sure that all image sizes are available on the cache.
      bottleImage: { merge: true },
      files: { merge: true },
    },
  },
};

export default typePolicies;
