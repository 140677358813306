/** @jsxImportSource theme-ui */
import { gql } from "urql";
import CardBlockFragment from "../Blocks/CardBlockFragment";
import IconBlockFragment from "../Blocks/IconBlockFragment";
import ImageBlockFragment from "../Blocks/ImageBlockFragment";
import MenuItemBlockFragment from "../Blocks/MenuItemBlockFragment";
import ProductBlockFragment from "../Blocks/ProductBlockFragment";
import ProfileBlockFragment from "../Blocks/ProfileBlockFragment";
import SocialMediaBlockFragment from "../Blocks/SocialMediaBlockFragment";
import TextBlockFragment from "../Blocks/TextBlockFragment";
import TitleBlockFragment from "../Blocks/TitleBlockFragment";
import VideoBlockFragment from "../Blocks/VideoBlockFragment";

const fragment = gql`
  fragment SimpleGridSectionFragment on SimpleGridSection {
    title
    callToAction {
      ...CallToAction
    }
    columnLayout
    gridColumnCount
    blocks {
      __typename
      ...BlocksFragment
      ... on GridColumnBlock {
        _key
        blocks {
          ...BlocksFragment
        }
        gridTemplateRows
        style {
          ...BlockStyle
        }
      }
    }
    style {
      ...SectionStyle
    }
  }

  fragment BlockStyle on BlockStyle {
    gridColumns
  }

  fragment BlocksFragment on CardBlockOrGridColumnBlockOrIconBlockOrImageBlockOrMenuItemBlockOrProductBlockOrProfileBlockOrSocialMediaBlockOrTextBlockOrTitleBlockOrVideoBlock {
    __typename
    ... on CardBlock {
      _key
      ...CardBlockFragment
    }
    ... on IconBlock {
      _key
      ...IconBlockFragment
    }
    ... on MenuItemBlock {
      _key
      ...MenuItemBlockFragment
    }
    ... on ProductBlock {
      _key
      ...ProductBlockFragment
    }
    ... on ProfileBlock {
      _key
      ...ProfileBlockFragment
    }
    ... on SocialMediaBlock {
      _key
      ...SocialMediaBlockFragment
    }
    ... on TextBlock {
      _key
      ...TextBlockFragment
    }
    ... on TitleBlock {
      _key
      ...TitleBlockFragment
    }
    ... on ImageBlock {
      _key
      ...ImageBlockFragment
    }
    ... on VideoBlock {
      _key
      ...VideoBlockFragment
    }
  }
  ${CardBlockFragment}
  ${IconBlockFragment}
  ${MenuItemBlockFragment}
  ${ProductBlockFragment}
  ${ProfileBlockFragment}
  ${SocialMediaBlockFragment}
  ${TextBlockFragment}
  ${TitleBlockFragment}
  ${ImageBlockFragment}
  ${VideoBlockFragment}
`;
export default fragment;
