import { ApolloProvider } from "@apollo/client";
import client from "@bottlebooks/bottlebooks-site-base/src/wrapElement/apolloClient/apolloClient";
import "@fontsource/open-sans/300.css";
import "@fontsource/open-sans/600.css";
import "@fontsource/source-sans-pro/400.css";
import "@fontsource/source-sans-pro/600.css";
import { Container, Stack, Text, Title } from "@mantine/core";
import { QueryClientProvider } from "@tanstack/react-query";
import { Outlet, useRouteError } from "react-router-dom";
import LayoutProviders from "~/components/LayoutProviders";
import queryClient from "~/queryClient";

export default function App() {
  return (
    <LayoutProviders>
      <QueryClientProvider client={queryClient}>
        <ApolloProvider client={client}>
          <Outlet />
        </ApolloProvider>
      </QueryClientProvider>
    </LayoutProviders>
  );
}

export function Catch() {
  const error = useRouteError();
  if (!error) return null;
  return (
    <LayoutProviders>
      <Container
        h="100vh"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Stack align="center" justify="center">
          <Title order={1} size="10rem" fw={900} c="gray.5">
            {error.statusCode ?? "500"}
          </Title>
          <Text size="xl" ta="center" maw={500} mb="xl">
            {error.message ?? "No message was provided."}
          </Text>
        </Stack>
      </Container>
    </LayoutProviders>
  );
}
