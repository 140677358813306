/** @jsxImportSource theme-ui */
import { Col, Container, Row } from "@bottlebooks/gatsby-theme-base/src";
import { FragmentType, graphql, useFragment } from "~/gql";
import useLink from "../../useLink";
import BrandLogo from "../Brand/BrandLogo";
import ProducerHeader from "../Producer/ProducerHeader.next";
import { useEventData } from "../SiteSearch/EventDataProvider.next";
import ChangeProducerLink from "./ChangeProducerLink";

const fragment = graphql(`
  fragment Registration_ProducerHeaderRow on Registration {
    ...Registration_ProducerHeader
  }
`);

const fragment2 = graphql(`
  fragment RegisteredBrand_ProducerHeaderRow on RegisteredBrand {
    brandId
    collection {
      logo {
        publicId
      }
    }
    ...RegisteredBrand_ProducerHeader
  }
  # fragment ProducerHeaderRow on Producer {
  #   ...BrandLogoLarge
  #   ...ProducerHeader
  #   ...useLink_Producer
  # }
`);

export default function ProducerHeaderRow({
  exhibitor: exhibitorData,
  producer: producerData,
}: {
  exhibitor: FragmentType<typeof fragment>;
  producer: FragmentType<typeof fragment2>;
}) {
  const link = useLink();
  const exhibitor = useFragment(fragment, exhibitorData);
  const producer = useFragment(fragment2, producerData);
  const { producers } = useEventData();
  const index = producers.findIndex(
    (aProducer) => aProducer.producerId === producer.brandId
  );
  const previous = index > 0 ? producers[index - 1] : null;
  const next = index < producers.length - 1 ? producers[index + 1] : null;
  return (
    <Container>
      <Row sx={{ justifyContent: "center" }}>
        <Col sx={{ marginTop: [-75, -50] }}>
          <BrandLogo
            size="large"
            brand={producer}
            collection={producer.collection}
            sx={{ backgroundColor: "background" }}
          />
        </Col>
        <Col
          sx={{
            paddingY: 4,
            flex: "1",
            minWidth: ["100%", "unset"],
            order: [1, "unset"],
          }}
        >
          <ProducerHeader
            as="h1"
            exhibitor={exhibitor}
            producer={producer}
            gap={3}
          />
        </Col>
        <Col sx={{ order: -1, minWidth: `${100 / 12}%`, textAlign: "right" }}>
          <ChangeProducerLink
            producer={previous}
            to={link.producer(previous)}
            direction="previous"
            sx={{ marginLeft: -4, marginRight: -3, marginTop: 3 }}
          />
        </Col>
        <Col sx={{ minWidth: `${100 / 12}%` }}>
          <ChangeProducerLink
            producer={next}
            to={link.producer(next)}
            sx={{ marginLeft: -3, marginRight: -4, marginTop: 3 }}
          />
        </Col>
      </Row>
    </Container>
  );
}

// export const fragment = graphql(`
//   fragment bb_Registration_ProducerHeaderRow on Bottlebooks_Registration {
//     ...bb_Registration_ProducerHeader
//   }
//   fragment bb_RegisteredBrand_ProducerHeaderRow on Bottlebooks_RegisteredBrand {
//     ...bb_RegisteredBrand_ProducerHeader
//   }
//   fragment ProducerHeaderRow on Producer {
//     ...BrandLogoLarge
//     ...ProducerHeader
//     ...useLink_Producer
//   }
// `);

// export const ProducerHeaderRowFragment = gql`
//   ${BrandLogoFragments.large}
//   ${ProducerHeaderFragment}
//   fragment bb_Registration_ProducerHeaderRow on Bottlebooks_Registration {
//     ...bb_Registration_ProducerHeader
//   }
//   fragment ProducerHeaderRow on Producer {
//     ...BrandLogoLarge
//     ...ProducerHeader
//   }
// `;
