/** @jsxImportSource theme-ui */
import {
  ToggleFavorite,
  useFavorite,
} from "@bottlebooks/bottlebooks-site-bookmarks/src";
import {
  Flex,
  Grid,
  SemiTitle,
  Stack,
  Text,
} from "@bottlebooks/gatsby-theme-base/src";
import { useLingui } from "@lingui/react";
import React, { Fragment } from "react";
import { FragmentType, graphql, useFragment } from "~/gql";
import Card from "../../Card";
import BrandLogo from "../Brand/BrandLogo";
import BrandRegion from "../Brand/BrandRegion.next";
import SeekingDistribution from "../Brand/BrandSeekingDistribution.next";
import ExhibitorStand from "../Exhibitor/ExhibitorStand.next";
import Image from "../Image/Image";
import ListRow from "../List/ListRow";
import ListRowBody from "../List/ListRowBody";

const fragment = graphql(`
  fragment ExhibitorCard_Registration on SingleRegistration {
    registrationId
    exhibitorId: companyId
    ...ExhibitorStand_SingleRegistration
    ...ExhibitorCardLogo
    profile {
      name
      ...BrandRegion
      offeringTypes
    }
  }
`);

export default function ExhibitorCard({
  exhibitor: data,
  className,
  style,
  elevation,
  children,
  ...rest
}: {
  exhibitor: FragmentType<typeof fragment>;
  className?: string;
  style?: React.CSSProperties;
  elevation?: number;
  onClick?: React.MouseEventHandler;
  children?: React.ReactNode;
  to?: string;
}) {
  return (
    <Card
      // The styles are applied to the Card component, not the Link component.
      className={className}
      style={style}
      elevation={elevation}
    >
      <ExhibitorCardLink {...rest}>
        <ExhibitorCardLogo data={data} />
        <ExhibitorCardMain>
          <ExhibitorCardExhibitor data={data} />
          <ExhibitorCardCharacteristics data={data} />
          {children}
        </ExhibitorCardMain>
      </ExhibitorCardLink>
      <ExhibitorCardBookmark data={data} />
    </Card>
  );
}

function ExhibitorCardLink({ children, ...rest }) {
  return (
    <ListRow
      {...rest}
      sx={{
        boxSizing: "border-box",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "stretch",
        borderRadius: "default",
      }}
    >
      {children}
    </ListRow>
  );
}

function ExhibitorCardBookmark({ data, ...rest }) {
  const exhibitor = useFragment(fragment, data);
  const favorite = useFavorite(
    exhibitor && { type: "registration", id: exhibitor.exhibitorId }
  );
  return (
    <ToggleFavorite
      variant="small"
      isToggled={favorite.isSet}
      onClick={() => favorite.toggle()}
      sx={{ position: "absolute", top: 0, right: 0 }}
    />
  );
}

const exhibitorCardLogoFragment = graphql(`
  fragment ExhibitorCardLogo on SingleRegistration {
    collection {
      logo {
        publicId
      }
    }
    profile {
      ...BrandLogo
    }
  }
`);
function ExhibitorCardLogo({
  data,
  ...rest
}: {
  data: FragmentType<typeof exhibitorCardLogoFragment>;
}) {
  const exhibitor = useFragment(exhibitorCardLogoFragment, data);
  return (
    <Grid sx={{ padding: 3, paddingBottom: 2, position: "relative" }} {...rest}>
      <BrandLogo
        brand={exhibitor}
        collection={exhibitor.collection}
        size="large"
        sx={{ boxShadow: "none", justifySelf: "center" }}
      />
      <Flex direction="column" justify="center" align="center">
        <SeekingDistribution
          data={exhibitor}
          variant="small"
          sx={{
            position: "absolute",
            bottom: 0,
            display: "block",
            marginX: "auto",
          }}
        />
      </Flex>
    </Grid>
  );
}

function ExhibitorCardMain({
  children,
  ...rest
}: {
  children: React.ReactNode;
}) {
  return (
    <ListRowBody
      sx={{ width: "100%", flex: "0 0 auto", paddingTop: 1 }}
      {...rest}
    >
      {children}
    </ListRowBody>
  );
}

function ExhibitorCardExhibitor({
  data,
  ...rest
}: {
  data: FragmentType<typeof fragment>;
}) {
  const exhibitor = useFragment(fragment, data);
  return (
    <Stack spacing={1} {...rest}>
      <ExhibitorStand exhibitor={exhibitor} sx={{ color: "lighterText" }} />
      <SemiTitle sx={{ paddingY: 0 }}>{exhibitor.name}</SemiTitle>
      <BrandRegion data={exhibitor} sx={{ color: "text" }} />
    </Stack>
  );
}

function ExhibitorCardCharacteristics({
  data,
  ...rest
}: {
  data: FragmentType<typeof fragment>;
}) {
  const exhibitor = useFragment(fragment, data);
  const { i18n } = useLingui();
  return (
    <Text variant="smallest" sx={{ color: "lighterText" }} {...rest}>
      {exhibitor.offeringTypes
        ?.map((characteristic) => i18n._(characteristic))
        .sort((a, b) => (a > b ? 1 : -1))
        .join(", ") || <Fragment>&nbsp;</Fragment>}
    </Text>
  );
}
