/** @jsxImportSource theme-ui */
import {
  ToggleFavorite,
  useFavorite,
} from "@bottlebooks/bottlebooks-site-bookmarks/src";
import { Col, Container, Row } from "@bottlebooks/gatsby-theme-base/src";
import { FragmentType, graphql, useFragment } from "~/gql";
import useLink from "../../useLink";
import BrandLogo from "../Brand/BrandLogo";
import ExhibitorHeader from "../Exhibitor/ExhibitorHeader.next";
import { useEventData } from "../SiteSearch/EventDataProvider.next";
import ChangeExhibitorLink from "./ChangeExhibitorLink";

const fragment = graphql(`
  fragment ExhibitorHeaderRow on SingleRegistration {
    companyId
    collection {
      logo {
        publicId
      }
    }
    profile {
      name
      ...BrandLogoLarge
      ...ExhibitorHeader
    }
  }
`);

export default function ExhibitorHeaderRow({
  data,
}: {
  data: FragmentType<typeof fragment>;
}) {
  const link = useLink();
  const registration = useFragment(fragment, data);
  const exhibitor = registration.profile;
  const { exhibitors } = useEventData();
  // Get the index of this exhibitor in the list of exhibitors
  const index = exhibitors.findIndex(
    (exhibitor) => exhibitor.brandId === registration.companyId
  );
  const previous = index > 0 ? exhibitors[index - 1] : null;
  const next = index < exhibitors.length - 1 ? exhibitors[index + 1] : null;

  // TODO use registrationId: exhibitor.registrationId,
  const favorite = useFavorite({
    type: "registration",
    id: registration.companyId,
  });
  return (
    <Container>
      <Row sx={{ justifyContent: "center" }}>
        <Col sx={{ marginTop: [-75, -50] }}>
          <BrandLogo
            size="large"
            brand={exhibitor}
            collection={registration.collection}
            sx={{ backgroundColor: "background" }}
          />
        </Col>
        <Col
          sx={{
            paddingY: 4,
            flex: "1",
            minWidth: ["100%", "unset"],
            order: [1, "unset"],
          }}
        >
          <ExhibitorHeader as="h1" data={exhibitor} gap={3} />
          <ToggleFavorite
            sx={{ marginTop: 3, marginLeft: -2, padding: 0, paddingRight: 3 }}
            isToggled={favorite.isSet}
            onClick={favorite.toggle}
          />
        </Col>
        <Col sx={{ order: -1, minWidth: `${100 / 12}%`, textAlign: "right" }}>
          <ChangeExhibitorLink
            exhibitor={previous}
            to={link.exhibitor(previous)}
            direction="previous"
            sx={{ marginLeft: -4, marginRight: -3, marginTop: 3 }}
          />
        </Col>
        <Col sx={{ minWidth: `${100 / 12}%` }}>
          <ChangeExhibitorLink
            exhibitor={next}
            to={link.exhibitor(next)}
            sx={{ marginLeft: -3, marginRight: -4, marginTop: 3 }}
          />
        </Col>
      </Row>
    </Container>
  );
}
