/** @jsxImportSource theme-ui */
import { FragmentType, graphql, useFragment } from "~/gql";
import ProductPriceExact from "./ProductPriceExact.next";
import ProductPriceRange from "./ProductPriceRange.next";
import getWfsPrice from "../../getWfsPrice";

export const fragment = graphql(`
  fragment ProductPrice on RegisteredProduct {
    pricing {
      priceStrategy
      ...ProductPriceExact
      ...ProductPriceRange
      # This doesn't seem to be displayed anywhere...
      # bottleSize

      price
      priceRange
    }
    wfsPrice: customFieldValue(key: "wfsPrice") {
      ...FieldValue
    }
    registration {
      exhibitorType: customFieldValue(
        key: "exhibitorType"
        _unstable_hidden: true
        experimentalKey: "jonathan@bottlebooks.me: @bottlebooks-site/packages/bottlebooks-site-base/src/components/ExhibitorsPage/exhibitorFilters.next.ts (wfsUk)"
      ) {
        __typename
        ... on SelectFieldValue {
          key
        }
      }
    }
  }
`);

export default function ProductPrice({
  pricing: data,
  prependPriceType = false,
  showMorePricingInfo = false,
  currencyDisplay,
  useGrouping,
  ...rest
}: {
  pricing: FragmentType<typeof fragment>;
  prependPriceType?: boolean;
  showMorePricingInfo?: boolean;
  currencyDisplay?: "symbol" | "code" | "name";
  useGrouping?: boolean;
  [x: string]: any;
}) {
  const registeredProduct = useFragment(fragment, data);
  if (!registeredProduct.wfsPrice?.text) return null;
  const pricing = getWfsPrice({
    exhibitorType: registeredProduct.registration.exhibitorType?.key,
    price: registeredProduct.wfsPrice?.text,
  })?.pricing;
  return (
    <ProductPriceExact
      pricing={pricing}
      prependPriceType={prependPriceType}
      currencyDisplay={currencyDisplay}
      useGrouping={useGrouping}
      {...rest}
    />
  );
}

ProductPrice.hasContent = hasContent;
function hasContent(data: FragmentType<typeof fragment>) {
  const registeredProduct = useFragment(fragment, data);
  const pricing = registeredProduct.pricing;
  if (pricing?.priceStrategy === "BY_PRICE" && pricing?.price != null)
    return true;
  if (
    pricing?.priceStrategy === "BY_PRICE_RANGE" &&
    pricing?.priceRange != null
  )
    return true;
  return false;
}
