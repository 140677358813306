import merge from "lodash/merge";
import { lighten } from "polished";
import { ThemeUIProvider } from "theme-ui";
import baseTheme from "../../../theme";
import { useCollectionLayout } from "../../CollectionLayoutProvider.next";

export default function BottlebooksStandardLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  return <ThemeUIProvider theme={useTheme()}>{children}</ThemeUIProvider>;
}

function useTheme() {
  const collectionLayout = useCollectionLayout();
  return {
    ...baseTheme,
    colors: {
      ...baseTheme.colors,
      ...(collectionLayout.primaryColor
        ? { primary: collectionLayout.primaryColor }
        : {}),
      ...(collectionLayout.accentColor
        ? { accent: collectionLayout.accentColor }
        : {}),
      ...(collectionLayout.primaryColor
        ? {
            secondary: lighten(0.1)(collectionLayout.primaryColor || "#000000"),
          }
        : {}),
    },
    fonts: merge({}, baseTheme.fonts, {
      heading: "Open Sans",
      body: "Source Sans Pro",
    }),
    fontWeights: {
      ...baseTheme.fontWeights,
      heading: "300",
      body: "400",
    },
    text: merge({}, baseTheme.text, {
      semiTitle: {
        ...baseTheme.text.semiTitle,
        fontSize: "body",
        fontFamily: "Source Sans Pro",
      },
    }),
  };
}
