/** @jsxImportSource theme-ui */
import { Box, Col, Container } from "@bottlebooks/gatsby-design-system";
import React from "react";
import { FragmentType, graphql, useFragment } from "~/gql";
import BrandDetailsSection from "../Brand/BrandDetailsSection.next";
import ContentRow from "../ContentRow";
import Representatives from "./Representatives.next";

const fragment = graphql(`
  fragment ExhibitorSections on SingleRegistration {
    ...Representatives
    profile {
      ...BrandDetailsSection
      ...BrandDistributors
    }
    __typename

    registeredBrands {
      nodes {
        distributionContacts {
          ...Contact
        }
        ...BrandDetailsSection_PresentedBrand
      }
    }
    forceHideBrands: customFieldValue(
      key: "forceHideBrands"
      _unstable_hidden: true
      experimentalKey: "jonathan@bottlebooks.me: @bottlebooks-site/packages/bottlebooks-site-base/src/components/ExhibitorsPage/exhibitorFilters.next.ts (wfsUk)"
    ) {
      ...FieldValue
    }
  }
`);

/**
 * A component to contain all the exhibitor details sections.
 */
export default function ExhibitorSections({
  data,
  eventId,
  ...rest
}: {
  data: FragmentType<typeof fragment> | null;
  eventId: string;
}) {
  const registration = useFragment(fragment, data);
  const profile = registration?.profile;
  if (!profile) throw new Error("No profile");
  return (
    <React.Fragment>
      <Box sx={{ paddingY: [0, 3] /*,  backgroundColor: 'light' */ }}>
        <Container sx={{ paddingX: [0, "gutter"] }} {...rest}>
          <ContentRow>
            <Col flex>
              <Representatives data={registration} sx={{ flex: "1 0 auto" }} />
            </Col>
          </ContentRow>
        </Container>
      </Box>
      <Container {...rest}>
        <ContentRow>
          <BrandDetailsSection
            data={registration.profile}
            isPresentedBrandsVisible={!registration.forceHideBrands?.value}
            presentedBrandsData={
              registration.__typename === "SingleRegistration"
                ? registration.registeredBrands.nodes
                : []
            }
          />
        </ContentRow>
      </Container>
    </React.Fragment>
  );
}
