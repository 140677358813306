import type {
  Aggregation,
  FilterDefinitions,
  Sorting,
} from "@bottlebooks/bottlebooks-site-base/src/components/Filters/useIndex";
import { defineMessage } from "@lingui/macro";
import { rankings } from "match-sorter";
import { FragmentType, graphql, useFragment } from "~/gql";
import { ExhibitorFiltersFragment } from "~/gql/graphql";
import { useCollectionLayout } from "../CollectionLayoutProvider.next";

const fragment = graphql(/* GraphQL */ `
  fragment exhibitorFilters on SingleRegistration {
    companyId
    collection {
      name
    }
    stand {
      name
      roomName
    }
    registrationStatus
    registeredProducts {
      totalCount
    }
    characteristicsSummary
    regionSummary
    denominationSummary
    priceSummary
    priceRangeSummary
    primaryVarietySummary
    productTypeSummary
    profile {
      name
      sortName
      countryName: country(format: LOCALIZED)
      region
      city
      distributionInCountries
      seekingDistributionInCountries
      # priceRanges
      sortName
      sustainabilityCertifications {
        name
      }
      offeringTypes
    }
    registeredBrands {
      nodes {
        ...exhibitorFilters_RegisteredBrand
      }
    }
    exhibitorType: customFieldValue(
      key: "exhibitorType"
      _unstable_hidden: true
      experimentalKey: "jonathan@bottlebooks.me: @bottlebooks-site/packages/bottlebooks-site-base/src/components/ExhibitorsPage/exhibitorFilters.next.ts (wfsUk)"
    ) {
      __typename
      ... on SelectFieldValue {
        value
      }
    }
  }

  fragment exhibitorFilters_RegisteredBrand on RegisteredBrand {
    brandId
    profile {
      distributionInCountries
      seekingDistributionInCountries
    }
    isSeekingDistribution
  }
`);

export const messages = [
  defineMessage({ id: "videos", message: "Videos" }),
  defineMessage({ id: "onlineShop", message: "Online shop" }),
  defineMessage({
    id: "imageGallery",
    message: "Image gallery",
  }),
];

// #region Aggregations

// Create a function to provide type safety to the individual aggregation definitions
function asAggregation(aggregation: Aggregation<ExhibitorFiltersFragment>) {
  return aggregation;
}

const aggregations = {
  collection: asAggregation({
    title: defineMessage({ message: "Collections" }),
    // multiSelect: false, // TODO Implement
    size: 20,
    get: (data: FragmentType<typeof fragment>) => {
      const exhibitor = useFragment(fragment, data);
      return [exhibitor.collection?.name];
    },
  }),
  location: asAggregation({
    title: defineMessage({ message: "Locations" }),
    // multiSelect: false, // TODO Implement
    size: 20,
    get: (exhibitor) => exhibitor.stand?.roomName || [],
  }),
  highlights: asAggregation({
    title: defineMessage({ message: "Highlights" }),
    // multiSelect: false, // TODO Implement
    size: 20,
    get: "profile.offeringTypes",
    translations: {
      videos: defineMessage({ message: "Videos" }),
      onlineShop: defineMessage({ message: "Online shop" }),
      imageGallery: defineMessage({ message: "Image gallery" }),
    },
  }),
  regionHierarchy: asAggregation({
    title: defineMessage({ message: "Winegrowing regions" }),
    conjunction: false,
    // multiSelect: false,
    size: 100,
    get: (exhibitor) => {
      if (!exhibitor?.regionSummary.length) return [];
      return exhibitor.regionSummary.filter(Boolean);
    }, // Excludes nulls
  }),
  sustainabilityCertifications: asAggregation({
    title: defineMessage({ message: "Certifications" }),
    conjunction: false,
    size: 100,
    get: (exhibitor) => {
      const certifications =
        exhibitor.profile?.sustainabilityCertifications || [];
      const certNames = certifications.map((cert) => cert.name).filter(Boolean);
      if (!certNames.length) return [];
      return ["Any", ...certNames];
    }, // Excludes nulls
  }),
  productionMethods: asAggregation({
    title: defineMessage({ message: "Production methods" }),
    conjunction: false,
    size: 20,
    get: (exhibitor) =>
      exhibitor?.characteristicsSummary
        ?.filter((value) =>
          [
            "VEGAN",
            "ORGANIC",
            "VEGETARIAN",
            "BIODYNAMIC",
            "KOSHER",
            "SUSTAINABLE",
          ].includes(value || "")
        )
        .map((e) => e || "") || [],
    translations: {
      VEGAN: defineMessage({ message: "vegan" }),
      ORGANIC: defineMessage({ message: "organic" }),
      VEGETARIAN: defineMessage({ message: "vegetarian" }),
      BIODYNAMIC: defineMessage({ message: "biodynamic" }),
      KOSHER: defineMessage({ message: "kosher" }),
      SUSTAINABLE: defineMessage({ message: "sustainable" }),
    },
  }),
  priceRanges: asAggregation({
    title: defineMessage({ message: "Price ranges" }),
    conjunction: false,
    // multiSelect: false,
    size: 100,
    get: (exhibitor) => {
      return exhibitor?.priceRangeSummary || [];
    }, // Excludes nulls
  }),
  productType: asAggregation({
    title: defineMessage({ message: "Product types" }),
    conjunction: false,
    get: (exhibitor) => {
      return exhibitor?.productTypeSummary?.filter(Boolean) || [];
    },
    translations: {
      WINE: defineMessage({ message: "Other wine" }),
      SPIRIT: defineMessage({ message: "Spirit" }),
      Stillwein: defineMessage({ message: "Wine" }),
      BEER: defineMessage({ message: "Beer and more" }),
    },
  }),
  // This needs to be customized for each event that needs it.
  // calculatedPriceRanges: asAggregation({
  //   title: defineMessage({ message: 'Price ranges' }),
  //   conjunction: false,
  //   size: 50,
  //   sort: 'term',
  //   order: 'asc',
  //   // This comes from ProductsTemplate.afterQuery.
  //   get: (exhibitor) => {
  //     const prices = (exhibitor?.priceSummary || []).map((price) => {
  //       if (price < 15) return '$';
  //       if (price < 25) return '$$';
  //       if (price < 45) return '$$$';
  //       if (price < 85) return '$$$$';
  //       return '$$$$$';
  //     });
  //     // Remove duplicates
  //     return [...new Set(prices || [])];
  //   }, // Excludes nulls
  //   translations: {
  //     $: defineMessage({ message: 'Less than $15' }),
  //     $$: defineMessage({ message: '$15-25' }),
  //     $$$: defineMessage({ message: '$25-45' }),
  //     $$$$: defineMessage({ message: '$45-85' }),
  //     $$$$$: defineMessage({ message: 'Greater than $85' }),
  //   },
  // }),
  primaryGrapeVariety: asAggregation({
    title: defineMessage({ message: "Primary grape varieties" }),
    conjunction: false,
    size: 300,
    get: (exhibitor) => {
      return exhibitor?.primaryVarietySummary || [];
    },
  }),
  countryName: asAggregation({
    title: defineMessage({ message: "Countries" }),
    size: 100,
    conjunction: false,
    // Remove null values
    get: (exhibitor) => exhibitor.profile?.countryName || [],
  }),
  denominationSummary: asAggregation({
    title: defineMessage({ message: "DOs" }),
    conjunction: false,
    // multiSelect: false,
    size: 100,
    get: (exhibitor) => {
      return exhibitor?.denominationSummary || [];
    }, // Excludes nulls
    sort: "term",
  }),
  isSeekingDistribution: asAggregation({
    title: defineMessage({ message: "Distribution in Market" }),
    size: 100,
    conjunction: false,
    sort: "term",
    // @ts-expect-error - this is a hack. Check exhibitors/index.tsx
    get: (exhibitor) => String(exhibitor.isSeekingDistribution || false),
    translations: {
      true: defineMessage({ message: "Seeking Distribution" }),
      false: defineMessage({ message: "Has Distribution" }),
    },
  }),
  distributionInCountries: asAggregation({
    title: defineMessage({ message: "Distribution in countries" }),
    size: 200,
    conjunction: true,
    multiSelect: true,
    get: (exhibitor) =>
      (exhibitor.profile?.distributionInCountries?.filter(
        Boolean
      ) as string[]) || [],
  }),
  seekingDistributionInCountries: asAggregation({
    title: defineMessage({ message: "Seeking distribution" }),
    size: 200,
    conjunction: true,
    sort: "term",
    multiSelect: true,
    get: (exhibitor) =>
      (exhibitor.profile?.seekingDistributionInCountries?.filter(
        Boolean
      ) as string[]) || [],
  }),
};

export function getAggregationList() {
  type AggregationKey = keyof typeof aggregations;
  // We have to force the key type. Otherwise, the type of the aggregation is inferred as
  // the type of the key, which is a string.
  const aggregationKeys = Object.keys(aggregations) as AggregationKey[];

  const aggregationList = aggregationKeys.map((key) => ({
    ...aggregations[key],
    key,
  }));
  return aggregationList;
}
// #endregion

// #region Sortings
// Sort helper functions
const sortBy = {
  standName: (exhibitor: ExhibitorFiltersFragment) => {
    return (
      (exhibitor?.stand?.name
        ?.split(/[\s,-]+/)
        .map((segment) =>
          segment === "Tisch"
            ? undefined
            : Number(segment)
            ? segment.padStart(3, "0")
            : segment
        )
        .filter(Boolean) as string[]) || []
    );
  },
  dataCompleteness: (exhibitor) =>
    completeness[exhibitor?.registrationStatus] -
    (exhibitor?.registeredProducts?.totalCount > 0 ? 1 : 0),
};

const sortings: Record<string, Sorting<ExhibitorFiltersFragment>> = {
  byStandName: {
    title: defineMessage({ message: "Table number" }),
    field: [sortBy.standName, "profile.sortName", "name"],
    group: (exhibitor) => exhibitor.stand?.roomName,
    groupHeader: "room",
    order: "asc",
  },
  // byDataCompleteness: {
  //   title: defineMessage({ message: 'By data completeness' }),
  //   field: [
  //     sortBy.standName,
  //     sortBy.dataCompleteness,
  //     'profile.sortName',
  //     'name',
  //   ],
  //   order: 'asc',
  // },
  byExhibitorName: {
    title: defineMessage({ message: "Exhibitor name" }),
    field: ["profile.sortName", "name"],
    order: "asc",
  },
};
// #endregion

// #region Search
const search = {
  threshold: rankings.WORD_STARTS_WITH,
  keys: [
    { key: "name", threshold: rankings.CONTAINS },
    (exhibitor) =>
      `${exhibitor.profile?.countryName || ""} ${
        exhibitor.profile?.region || ""
      } ${exhibitor.profile?.city || ""}`.trim(),
    (exhibitor) => exhibitor?.stand?.name?.replace(/[()]/g, "") || "",
  ],
};
// #endregion

// #region useExhibitorFilters
export default function useExhibitorFilters(): FilterDefinitions<ExhibitorFiltersFragment> {
  const collection = useCollectionLayout();
  const selectedAggregations =
    window.bbExhibitorFiltersPreview || collection.siteConfig.exhibitorFilters;

  const baseConfiguration = {
    aggregations,
    sortings,
    search,
  };
  if (!selectedAggregations?.length) return baseConfiguration;
  const pickedAggregations = selectedAggregations.reduce<
    Record<string, Aggregation<ExhibitorFiltersFragment>>
  >((acc, aggregationKey) => {
    // @ts-expect-error - We need to coerse type from string to key
    const aggregation = baseConfiguration.aggregations[aggregationKey];
    // This shouldn't happen
    if (!aggregation) return acc;
    acc[aggregationKey] = aggregation;
    return acc;
  }, {});

  return { ...baseConfiguration, aggregations: pickedAggregations };
}
// #endregion

const completeness = {
  ORGANIZER_REVIEWED: 1,
  STEP_COMPLETED: 2,
  STEP_PAYMENT: 2,
  STEP_REVIEW: 3,
};
