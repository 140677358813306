import ProducersPage from "@bottlebooks/bottlebooks-site-base/src/components/ProducersPage/ProducersPage.next";
import { LoaderFunctionArgs, useLoaderData, useParams } from "react-router-dom";
import { z } from "zod";
import { bbCollectionIdSchema } from "~/bbCollectionIdSchema";
import { graphql } from "~/gql";
import { getFragment } from "~/gql-local/fragment-masking";
import graphQLClient from "~/graphQLClient";

const paramsSchema = z.object({
  locale: z.enum(["en", "de", "es", "fr"]),
  collectionId: z.string(),
});

export default function ProducersTemplate() {
  const { locale, collectionId: collectionIdSegment } = paramsSchema.parse(
    useParams()
  );
  const collectionId = bbCollectionIdSchema.parse(collectionIdSegment);
  const data = useLoaderData();
  const event = getFragment(eventFragment, data);
  const producersMap = event?.producers.nodes.reduce((acc, producer) => {
    acc[producer.producerId] = producer;
    return acc;
  }, {});

  const extractedRegistrations = event?.registeredBrands.nodes
    .flatMap((registeredBrand) => {
      const producer = getFragment(fragment, registeredBrand);
      const classicProducer = producersMap[producer.brandId];
      return {
        ...classicProducer,
        ...producer,
        ...producer.profile,
        exhibitor: registeredBrand.registration.profile,
      };
    })
    ?.filter(Boolean);

  if (!extractedRegistrations) return <div>No registrations found.</div>;
  return (
    <ProducersPage
      collectionId={collectionId}
      producers={extractedRegistrations}
      locale={locale}
      // TODO: Not sure what this is
      location={"/"}
    />
  );
}

export async function Loader({ params }: LoaderFunctionArgs) {
  const { collectionId: collectionIdSegment, locale } =
    paramsSchema.parse(params);
  const collectionId = bbCollectionIdSchema.parse(collectionIdSegment);
  const { event } = await graphQLClient.request(
    graphql(/* GraphQL */ `
      query ProducersPage($collectionId: ID!, $locale: ContentLocale) {
        event(eventId: $collectionId, locale: $locale) {
          eventId
          ...ProducersPage_Event
        }
      }
    `),
    { collectionId, locale },
    { "bottlebooks-use-request-cache": "true" }
  );
  return event;
}

const eventFragment = graphql(/* GraphQL */ `
  fragment ProducersPage_Event on Event {
    eventId
    producers {
      nodes {
        producerId
        ...ProducersPage
      }
    }
    registeredBrands {
      nodes {
        __typename
        registrationId
        brandId
        # This is pretty ugly because ProducersPage has a fragment on Producer, not RegisteredBrand
        # This is required because producers resolver doesn't know about child collections
        producerId: brandId
        ...ProducerDetail_ProducersPage
        registration {
          ...ProducerListRow_Registration
        }
      }
    }
    # used to get the denomination summary for each producer
    registeredProducts {
      totalCount
      byProducer: group(field: producerId, sort: { fields: fieldValue }) {
        fieldValue
        totalCount
        nodes {
          product {
            ... on Wine {
              denomination
            }
          }
        }
      }
    }
  }
`);

const fragment = graphql(/* GraphQL */ `
  fragment ProducerDetail_ProducersPage on RegisteredBrand {
    ...ProducerCard_RegisteredBrand
    ...ProducerFilters_RegisteredBrand
    ...producerFilters
  }
`);
