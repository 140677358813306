import { z } from "zod";

const envVariables = z.object({
  VITE_API_URL: z
    .string()
    .url()
    .regex(/[^/]$/, "API URL must not end with a slash"),
  VITE_GRAPHQL_URL: z
    .string()
    .url()
    .regex(/[^/]$/, "GraphQL URL must not end with a slash"),
  VITE_LOGIN_URL: z
    .string()
    .url()
    .regex(/[^/]$/, "Login URL must not end with a slash"),
  VITE_GOOGLE_MAPS_API_KEY: z.string(),
});

const env = envVariables.parse(import.meta.env);

export default env;

declare global {
  interface ImportMetaEnv extends z.input<typeof envVariables> {}
}
