import { ApolloClient, InMemoryCache } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import fetch from "isomorphic-fetch";
import possibleTypes from "./possibleTypes";
import typePolicies from "./typePolicies";
import env from "../../env";

// TODO set this to the server path.
const graphqlUri = env.VITE_GRAPHQL_URL || "https://app.bottlebooks.me/graphql";
console.log(graphqlUri);

const client = new ApolloClient({
  cache: new InMemoryCache({
    possibleTypes,
    typePolicies,
  }),
  link: createUploadLink({
    uri: graphqlUri,
    credentials: "include",
    // Forces caching of the result although we call Set-Cookie on every request.
    headers: { "gcdn-force": 1 },
    fetch,
  }),
});

export default client;
