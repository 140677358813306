/** @jsxImportSource theme-ui */
import {
  Box,
  Container,
  Flex,
  Grid,
  Stack,
} from "@bottlebooks/gatsby-theme-base/src";
import { defineMessage } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import React from "react";
import { FragmentType, graphql, useFragment } from "~/gql";
import useLink from "../../useLink";
import ActiveFiltersBar from "../Filters/ActiveFiltersBar";
import FilterField from "../Filters/FilterField";
import {
  filtersCleared,
  filterToggled,
  searchTermChanged,
  sortChanged,
} from "../Filters/filterReducer";
import Filters, { useFiltersDisplay } from "../Filters/Filters";
import useIndex from "../Filters/useIndex";
import useLocationSyncedState from "../Filters/useLocationSyncedState";
import { ProducerCard, ProducersList } from "../ProducersList";
import Sort from "../ProductsPage/Sort";
// import SEO from "../seo.js";
// import useEventData from "../SiteSearch/useEventData";
import EmptyState from "./EmptyState";
import useProducerFilters from "./producerFilters.next";
import ProducersPageTitle from "./ProducersPageTitle";
import useProducerSearch from "./useProducerSearch";

const PAGE_SIZE = 30;
const fragment = graphql(/* GraphQL */ `
  fragment ProducersPage on Producer {
    brandId
    producerId
    ...ProducerListRow
    # ...ProducerFilters
    # ...useLink_bb_Producer
  }
`);

export default function ProducersPage({
  producers: producersData,
  collectionId,
  location,
  locale,
}: {
  producers: FragmentType<typeof fragment>;
  collectionId?: string;
  location: { href: string; hash: string; state: any };
  locale: string;
}) {
  const producers = useFragment(fragment, producersData);
  const { i18n } = useLingui();
  const [state, dispatch] = useLocationSyncedState({
    hash: window.location.hash,
    state: window.history.state,
  });
  const filterConfig = useProducerFilters();
  const link = useLink();

  // Merge exhibitor data into producers
  // const { exhibitors } = useEventData();
  // const enrichedProducers = producers.map((producer) => {
  //   const exhibitor = exhibitors.find(
  //     ({ exhibitorId }) => exhibitorId === producer.exhibitorId
  //   );
  //   return {
  //     ...producer,
  //     exhibitor,
  //   };
  // });
  const searched = useProducerSearch(producers, state.searchTerm);
  const sortings = Object.entries(filterConfig.sortings).map(
    ([key, sorting]) => ({ key, ...sorting })
  );
  const activeSorting =
    sortings.find((sorting) => sorting.key === state.sort) || sortings[0];
  const sort = activeSorting.key;
  const { getToggleProps, getFiltersProps, isVisibleOnDesktop } =
    useFiltersDisplay();

  // Filter the exhibitors by term and facets.
  const [limit, setLimit] = React.useState(PAGE_SIZE);
  const index = useIndex(searched, filterConfig);
  const filters = Object.fromEntries(
    Object.keys(filterConfig.aggregations).map((key) => [key, state[key] || []])
  );
  const { data, pagination } = index.search({ per_page: limit, sort, filters });
  const showMore = React.useCallback(
    () => setLimit((limit) => limit + PAGE_SIZE),
    []
  );

  /**
   * @param {{key: string; doc_count: number; selected: boolean; }} bucket
   */
  function toggleFilter(aggregation, bucket) {
    dispatch(filterToggled(aggregation.name, bucket.key));
    setLimit(PAGE_SIZE);
    // if (!bucket.selected) setFiltersAreVisible(false);
  }

  const mainColumn = {
    gridColumnStart: [1, isVisibleOnDesktop ? 2 : 1],
    gridColumnEnd: -1,
  };

  const hasMore = pagination.total > data.items.length;
  const page = { path: location.href };
  return (
    <Box sx={{ backgroundColor: "light" }}>
      {/* <SEO
        page={page}
        title={i18n._(defineMessage({ message: `Producers` }))}
      /> */}
      <Box sx={{ backgroundColor: "light" }}>
        <Container fluid sx={{ paddingTop: 3, paddingBottom: 6 }}>
          <Stack>
            <ActiveFiltersBar
              searchTerm={state.searchTerm}
              aggregations={data.aggregations}
              definitions={filterConfig.aggregations}
              onToggleFilter={toggleFilter}
              onClearFilters={() => dispatch(filtersCleared())}
            />
            <Flex
              gap={2}
              align="baseline"
              justify="space-between"
              sx={{ flexDirection: ["column", "row"] }}
            >
              <ProducersPageTitle
                totalCount={producers.length}
                count={pagination.total}
              />
              {/* <DownloadMyBookmarks
                collectionId={collectionId}
                locale={locale}
              /> */}
            </Flex>
            <Grid
              width={254}
              repeat="fill"
              sx={{ alignItems: "center", gridAutoFlow: "dense" }}
            >
              <Sort
                sortings={sortings}
                value={sort}
                onChange={(value) => dispatch(sortChanged(value))}
                sx={{ gridColumn: "-2/-1", textAlign: "right" }}
              />
              <FilterField
                placeholder={i18n._(
                  defineMessage({ message: "Enter wine or producer name" })
                )}
                value={state.searchTerm}
                onChange={(value) => {
                  setLimit(PAGE_SIZE);
                  return dispatch(searchTermChanged(value));
                }}
                variant="large"
                sx={{
                  borderColor: "borderSecondary",
                  gridColumn: ["1/-1", "2/-2"],
                }}
              />
              <Filters.ToggleDesktop {...getToggleProps()} />
              <Filters.ToggleMobile {...getToggleProps()} />
            </Grid>
            <Grid width={254} repeat="fill" sx={{ alignItems: "flex-start" }}>
              <Filters
                key={state.searchTerm}
                aggregations={data.aggregations}
                definitions={filterConfig.aggregations}
                onToggleFilter={toggleFilter}
                totalCount={producers.length}
                count={pagination.totalCount}
                sx={{
                  maxHeight: [null, "100vh"],
                  overflowY: [null, "auto"],
                  position: [null, "sticky"],
                  top: 0,
                }}
                {...getFiltersProps()}
              />
              {!pagination.total ? (
                <EmptyState sx={mainColumn} />
              ) : (
                <ProducersList
                  hasMore={hasMore}
                  showMore={showMore}
                  width={254}
                  sx={{ gridAutoFlow: "dense", ...mainColumn }}
                >
                  {data.items.map((producer) => (
                    <ProducerCard
                      key={`${producer.registrationId}-${producer.producerId}`}
                      to={link.registeredBrand(producer)}
                      producer={producer}
                    />
                  ))}
                </ProducersList>
              )}
            </Grid>
          </Stack>
        </Container>
      </Box>
    </Box>
  );
}
